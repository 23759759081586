import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { UsersService } from 'src/Services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AppConsts } from 'src/app/Shared/AppConsts';
import { StateService } from 'src/Services/state.service';
import { SubscriberMasterModel, SubscriberApplicationHistory } from 'src/Models/SubscriberMaster.model';
import { LocationService } from 'src/Services/location.service';
import { ProviderZoneModel } from 'src/Models/providerZoneModel';
import { ProviderZone } from 'src/Services/providerzone.services';
import { SubscriberService_ } from 'src/Services/subscriver_.service';
import { alphabetOnly } from 'src/app/Shared/commonMethods';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { OnlineRegisterService } from 'src/Services/onlineRegister.services';
import { OnlineRegistrationModel } from 'src/Models/onlineRegistrationModel';
import { ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css']
})
export class AddNewUserComponent implements OnInit {
  cApp = new SubscriberMasterModel();
  providerId = 0;
  userId: number = 0;

  // Personal Details
  personalDetailsForm: FormGroup;
  ssnValue: string = '';
  tinValue: string = '';
  isSubmittedPersonalDetails = false;

  // Address
  isSubmittedAddress = false;
  addressForm: FormGroup;
  statelist = [];
  stateShortNameTempValue: string = '';
  stateShortNameValue: string = '';
  isTribalLands: boolean = false;

  // Dependent
  isSubmittedDependant = false;
  isDependantSSN: boolean = true;
  dependentForm: FormGroup;
  startingUrl: string;
  maxDateValue = new Date();
  dependentSSNValue: string = '';
  dependentTINValue: string = '';
  isDependent: boolean = false;

  // Qualify
  isSubmittedQualify = false;
  notQualified: boolean = false;
  qualifyType: number = 1;
  qualifyForm: FormGroup;

  isTribalSpecific: boolean;
  isUncheckEBI: boolean = false;

  isSNAPChecked: boolean = false;
  isMedicalIdChecked: boolean = false;
  isSSIChecked: boolean = false;
  isFPHAChecked: boolean = false;
  isVPChecked: boolean = false;
  isBIAChecked: boolean = false;
  isTribalTANFChecked: boolean = false;
  isFDPIRChecked: boolean = false;
  isTribalHSChecked: boolean = false;
  isShowErrorMessage: boolean = false;

  isPEASAChecked: boolean = false;
  isSLBAChecked: boolean = false;
  isFPGChecked: boolean = false;
  isSLOIChecked: boolean = false;
  isELIPCovid19Checked: boolean = false;
  isWICChecked: boolean = false;

  // Other Information
  otherDetailForm: FormGroup;
  isSubmittedOtherDetails: boolean = false;
  public customPatterns = { '0': { pattern: new RegExp('\[a-zA-Z0-9\]') } };

  // Localtion
  dropdownSettings = {};
  selectedLocation = [];
  locationText = '';
  locations = [];
  locationList: any[] = [];

  // Zone
  listOfZone: ProviderZoneModel[];
  dropdownZoneList = [];
  selectedZoneItems = [];
  dropdownZoneSettings = {};
  zoneList: any[] = [];

  // Online Registration
  onlineRegistrationId: number = 0;
  registerModel = new OnlineRegistrationModel();

  // Confirm Reason Form
  confirmReasonForm: FormGroup;
  isAgree: boolean = false;
  singleProviderZone = new ProviderZoneModel();
  isEligiblityError: boolean = false;
  isEnrolledError: boolean = false;
  eligiblityErrorMessage: any;
  enrolledErrorMessage: any;
  isloading: boolean = false;
  subscriberApplicationData: any;
  badRequestError: any;
  // @ViewChild('opneModal') opneModal: ElementRef;

  resubmitApplicationHistoryId: number = 0;
  isEnrolledRadioButtonDisable: boolean = false;
  isAlreadyEnrolledRadioChecked: boolean = false;
  isNewExistingUser: string = 'New';

  isFrontEligibliityComplated: boolean = false;
  onlineApplicationHistoryId: number = 0;
  isTransferButtonShow: boolean = false;
  isTransferRadioButtonShow: boolean = false;

  publicHousingCodeValue:string='';
  optionA:boolean=false;
  optionB:boolean=false;
  optionC:boolean=false;
  optionD:boolean=false;

  apperrorMessage: any='';
  @ViewChild('openConfirmModal') openConfirmModal: ElementRef;
  @ViewChild('openProceedModal') openProceedModal: ElementRef;

  consentZonelist=[];
  enableConsentDateTime=false;
  isApplicationidValue: boolean = true;
  typeofapplication:number=1;

  constructor(private locationService: LocationService,
    private stateService: StateService, private fb: FormBuilder,
    private router: Router, private userService: UsersService,
    private titleService: Title,
    private providerZone: ProviderZone,
    private subscriberService: SubscriberService_,
    private globle: Globle, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private onlineRegisterService: OnlineRegisterService,
  ) {
    this.enableConsentDateTime=environment.enableconsentdatetime;
    this.providerId = this.globle.cUser.providerId;
    this.userId = this.globle.cUser.id;
    this.getState();
    
    if(this.enableConsentDateTime){
      this.getConsentZone();
    }

    this.onlineRegistrationId = getIntParam(this.activatedRoute.params, 'id');
    this.isFrontEligibliityComplated = sessionStorage.getItem('isFrontEligibliityComplated') == 'true' ? true : false;

    this.dropdownZoneSettings = {
      singleSelection: true,
      text: "Select Zone",
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      enableSearchFilter: true,
      enableCheckAll: true,
      badgeShowLimit: 1,
      showCheckbox: false,
      noDataLabel: 'No Zone available',
    };

    this.dropdownSettings = {
      singleSelection: true,
      text: "Select location",
      enableSearchFilter: true,
      classes: "dropdownborders",
      noDataLabel: 'No location available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      //lazyLoading: true,
      //searchBy: ['name'],
      escapeToClose: true
    };

    this.titleService.setTitle('Applicant - Add New');
    this.typeofapplication = 1;

    if (sessionStorage.getItem('applicationHistoryId') != null) {
      this.resubmitApplicationHistoryId = +sessionStorage.getItem('applicationHistoryId')
    }
    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
    {
      this.isEnrolledRadioButtonDisable = true;
      this.isTransferRadioButtonShow = false;
    }
    else
    {
      this.isEnrolledRadioButtonDisable = false;
      this.isTransferRadioButtonShow = true;
    }

  }

  ngOnInit() {
    this.personalDetailsFormInit();
    this.AddressFormInit();
    this.dependentFormInit();
    this.qualifyFormInit();
    this.otherDetailFormInit();
    this.getProviderLocation();
    this.confirmReasonFormInit();
    if (this.onlineRegistrationId > 0) {
      if (this.isFrontEligibliityComplated) {
        this.onlineApplicationHistoryId = +sessionStorage.getItem('isOnlineApplicationHistoryId');
        this.GetEligibilityApplicationFindByApplicationHistoryId()
      }
      else
        this.GetOnlineRegistrationFindById();
    }

    if (this.resubmitApplicationHistoryId > 0 && this.isFrontEligibliityComplated == false) {
      this.GetResumbitApplicationData();
    }
  }

  redirectToList() {
    this.router.navigate(['admin/applicants']);
  }

  get fpi() { return this.personalDetailsForm.controls; }
  get fad() { return this.addressForm.controls; }
  get fdp() { return this.dependentForm.controls; }
  get odf() { return this.otherDetailForm.controls; }

  personalDetailsFormInit() {
    this.personalDetailsForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      phoneNo: ['', Validators.required],
      dateofBirth: ['', Validators.required],
      emailId: ['', [Validators.pattern(AppConsts.EMAIL_REGEXP), Validators.required]],
      sSN: [''],
      tIN: [''],
      // sSN: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],	
      // tIN: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      chkAgreement: [''],
      chkapplicationid:[true]
    });

    this.personalDetailsForm.get('tIN').setValidators([]);
    this.personalDetailsForm.get('tIN').updateValueAndValidity();
    this.personalDetailsForm.get('tIN').setValue('');
  }

  onChangeSSN(type: string) {
    if (this.tinValue == '' && this.ssnValue == '') {
      this.personalDetailsForm.get('tIN').enable();
      this.personalDetailsForm.get('sSN').enable();
    }
    else if (this.ssnValue != '' && this.tinValue == '') {
      this.personalDetailsForm.get('tIN').disable();
      this.personalDetailsForm.get('sSN').enable();
    }
    else if (this.ssnValue == '' && this.tinValue != '') {
      this.personalDetailsForm.get('tIN').enable();
      this.personalDetailsForm.get('sSN').disable();
    }

    if (type == 'SSN' && this.tinValue == '') {
      this.personalDetailsForm.get('sSN').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      this.personalDetailsForm.get('tIN').setValidators([]);
      this.personalDetailsForm.get('sSN').updateValueAndValidity();
      this.personalDetailsForm.get('tIN').updateValueAndValidity();
      this.personalDetailsForm.get('tIN').setValue('');
    }
    else if (this.ssnValue == '') {
      this.personalDetailsForm.get('tIN').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
      this.personalDetailsForm.get('sSN').setValidators([]);
      this.personalDetailsForm.get('tIN').updateValueAndValidity();
      this.personalDetailsForm.get('sSN').updateValueAndValidity();
      this.personalDetailsForm.get('sSN').setValue('');
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveDetails(personalDetailsForm, addressForm, dependentForm, qualifyForm, otherDetailForm,isSecondbuttonsubmit) {

    // if (!this.isAgree) {
    //   this.opneModal.nativeElement.click();
    //   return;
    // }
    this.apperrorMessage="";

    this.isEligiblityError = false;
    this.isEnrolledError = false;

    this.isSubmittedPersonalDetails = true;
    this.isSubmittedAddress = true;
    this.isSubmittedDependant = true;
    this.isSubmittedOtherDetails = true;

    if (this.personalDetailsForm.invalid) {
      return;
    }

    this.isSubmittedPersonalDetails = true;
    if (this.addressForm.invalid) {
      return;
    }

    if (this.isDependent) {
      if (this.dependentForm.invalid) {
        return;
      }
    }

    if (this.qualifyForm.invalid) {
      return;
    }

    if (this.otherDetailForm.invalid) {
      return;
    }

    if(this.isFPHAChecked && this.publicHousingCodeValue==''){
      this.toastr.error("Please select federal public housing program options.", "Error");
      return;
    }
    if (this.isMultipleQualifyProgramSelected()) {
      this.toastr.error("Please select only one qualify program.", "Error");
      return;
    }
    // if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
    //   && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
    //   && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
    //   && !this.isSLOIChecked && !this.isELIPCovid19Checked) {
    //   this.toastr.error("Select at list one qualify option.", "Error");
    //   this.isShowErrorMessage = true;
    //   return;
    // }
    // else
    //   this.isShowErrorMessage = false;


    // if (this.confirmReasonForm.value['isEnrolledInNLAD'] == 'Yes') {

    //   if (this.otherDetailForm.invalid) {
    //     return;
    //   }

    // }

    // if (this.locationList.length == 0) {
    //   this.toastr.error('Please select location', 'Error');
    //   return;
    // }

    // if (this.zoneList.length == 0) {
    //   this.toastr.error('Please select zone', 'Error');
    //   return;
    // }

    if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
      this.toastr.error("Only alphbet allow in mail address city.", "Error");
      return;
    }
    // if (otherDetailForm.controls.accountNumber.value == '' || otherDetailForm.controls.accountNumber.value == null) {
    //   this.toastr.error("Please enter account number.", "Error");
    //   return;
    // }
    // else {
    //   if (otherDetailForm.controls.accountNumber.value.length != 16) {
    //     this.toastr.error("Invalid length of account number.", "Error");
    //     return;
    //   }
    // }
    if (otherDetailForm.controls.applicationNumber.value == '' || otherDetailForm.controls.applicationNumber.value == null) {
      this.toastr.error("Please enter application number.", "Error");
      return;
    }
    else {
      if (otherDetailForm.controls.applicationNumber.value.length < 11) {
        this.toastr.error("Invalid length of application number.", "Error");
        return;
      }
    }

    this.cApp.subscriberPersonalDetails = personalDetailsForm.value;
    this.cApp.subscriberAddress = addressForm.value;
    this.cApp.subscriberDependent = dependentForm.value;
    this.cApp.subscriberQualify = qualifyForm.value;

    this.cApp.subscriberAddress.stateShortName = this.stateShortNameValue;
    this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;

    this.cApp.subscriberApplicationHistory.userId = 0;
    this.cApp.subscriberApplicationHistory.approvalDate = null;
    this.cApp.subscriberApplicationHistory.createdDate = null;
    this.cApp.subscriberApplicationHistory.modifiedDate = null;

    if (this.cApp.subscriberAddress.addressType == 'Home') {
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;
      if (this.cApp.subscriberAddress.stateIdTemp == null || this.cApp.subscriberAddress.stateIdTemp == 0) {
        return;
      }
      if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
        this.toastr.error("Only alphbet allow in mail city.", "Error");
        return;
      }
    }
    else {
      if (!alphabetOnly(addressForm.controls.city.value)) {
        this.toastr.error("Only alphbet allow in city.", "Error");
        return;
      }
      this.cApp.subscriberAddress.cityTemp = this.cApp.subscriberAddress.city;
      this.cApp.subscriberAddress.stateIdTemp = this.cApp.subscriberAddress.stateId;
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameValue;
      this.cApp.subscriberAddress.streetNumberTemp = this.cApp.subscriberAddress.streetNumber;
      this.cApp.subscriberAddress.zipcodeTemp = this.cApp.subscriberAddress.zipcode;
    }
    this.cApp.subscriberAddress.isTribalLands = this.isTribalLands;

    let dt = new Date(this.cApp.subscriberPersonalDetails.dateofBirth);
    this.cApp.subscriberPersonalDetails.dateofBirth = this.setDateFormat(dt, false);

    if (this.isDependent) {
      dt = new Date(this.cApp.subscriberDependent.dateofBirth);
      this.cApp.subscriberDependent.dateofBirth = this.setDateFormat(dt, false);
    }
    if (this.confirmReasonForm.value['isEnrolledInNLAD'] == 'Yes') {
      let enrollDate = otherDetailForm.value.enrollmentDate;
      let locationId = this.locationList.join(',');
      let zone = this.zoneList.join(',');

      if (otherDetailForm.value.accountNumber != null && otherDetailForm.value.accountNumber != '' && typeof (otherDetailForm.value.accountNumber) != undefined) {
        // this.cApp.subscriberPersonalDetails.locationCode = otherDetailForm.value.accountNumber.toString().substring(0, 6);
        // this.cApp.subscriberPersonalDetails.customerId = otherDetailForm.value.accountNumber.toString().substring(6, 14);        
      }

      this.cApp.subscriberPersonalDetails.applicationNumber = otherDetailForm.value['applicationNumber'];
      this.cApp.subscriberPersonalDetails.enrollDate = enrollDate;
      this.cApp.subscriberPersonalDetails.locationId = locationId;
      this.cApp.subscriberPersonalDetails.zone = zone;

      this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = otherDetailForm.value.eligibilityExpirationDate;
      this.cApp.subscriberPersonalDetails.transactionEffectiveDate = otherDetailForm.value.transactionEffectiveDate;
      this.cApp.subscriberPersonalDetails.serviceInitializationDate = otherDetailForm.value.serviceInitializationDate;


      let dt = new Date(this.cApp.subscriberPersonalDetails.enrollDate);
      this.cApp.subscriberPersonalDetails.enrollDate = this.setDateFormat(dt, true);
      dt = new Date(this.cApp.subscriberPersonalDetails.transactionEffectiveDate);
      this.cApp.subscriberPersonalDetails.transactionEffectiveDate = this.setDateFormat(dt, true);
      dt = new Date(this.cApp.subscriberPersonalDetails.serviceInitializationDate);
      this.cApp.subscriberPersonalDetails.serviceInitializationDate = this.setDateFormat(dt, true);
      dt = new Date(this.cApp.subscriberPersonalDetails.eligibilityExpirationDate);
      this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = this.setDateFormat(dt, true);
    }
    else {
      let locationId = this.locationList.join(',');
      let zone = this.zoneList.join(',');

      this.cApp.subscriberPersonalDetails.locationId = locationId;
      this.cApp.subscriberPersonalDetails.zone = zone;

      // this.cApp.subscriberPersonalDetails.locationCode = otherDetailForm.value.accountNumber.toString().substring(0, 8);
      // this.cApp.subscriberPersonalDetails.customerId = otherDetailForm.value.accountNumber.toString().substring(8, 16);
      this.cApp.subscriberPersonalDetails.applicationNumber = otherDetailForm.value['applicationNumber'];
      if (otherDetailForm.value.eligibilityExpirationDate != null && otherDetailForm.value.eligibilityExpirationDate != '') {
        this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = otherDetailForm.value.eligibilityExpirationDate;
        dt = new Date(this.cApp.subscriberPersonalDetails.eligibilityExpirationDate);
        this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = this.setDateFormat(dt, true);
      }
      else {
        this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = null;
      }

    }

    if (this.onlineRegistrationId > 0 && this.onlineRegistrationId != null) {
      this.cApp.subscriberPersonalDetails.onlineRegistrationID = this.onlineRegistrationId.toString();
    }

    // Application Stage
    this.cApp.subscriberApplicarionStage.isPersonalDetailsCompleted = true;
    this.cApp.subscriberApplicarionStage.isAddressCompleted = true;
    this.cApp.subscriberApplicarionStage.isQualifiedCompleted = true;
    this.cApp.subscriberApplicarionStage.isAgreementCompleted = true;
    this.cApp.subscriberApplicarionStage.isReviewCompleted = true;

    // Other Details
    this.cApp.subscriberOthers.deviceMake = '';
    this.cApp.subscriberOthers.deviceReimbursementDate = null;
    this.cApp.subscriberOthers.deviceType = '';
    this.cApp.subscriberOthers.expectedRate = '';
    this.cApp.subscriberOthers.expectedRateDevice = '';
    this.cApp.subscriberOthers.latitude = '';
    this.cApp.subscriberOthers.longitude = '';
    this.cApp.subscriberOthers.schoolName = '';

    this.cApp.subscriberPersonalDetails.isEnrolledInNLAD = this.confirmReasonForm.value['isEnrolledInNLAD'];
    this.cApp.subscriberApplicationHistory.createdById = this.userId;
    this.cApp.subscriberApplicationHistory.modifiedById = this.userId;
    this.cApp.subscriberPersonalDetails.isAlreadyEnrolledRadioChecked = this.isAlreadyEnrolledRadioChecked;
    this.cApp.subscriberPersonalDetails.isNewExistingUser = this.isNewExistingUser;
    
    this.cApp.subscriberPersonalDetails.isConsumerFee = otherDetailForm.value.isConsumerFee;
    this.cApp.subscriberQualify.publicHousingCode=this.publicHousingCodeValue;

    if (this.enableConsentDateTime) {
      let formattedDate =''
      if(otherDetailForm.value.consentDateTime!=null && otherDetailForm.value.consentDateTime!=''){
        this.cApp.subscriberPersonalDetails.ConsentDateTime = otherDetailForm.value.consentDateTime.toString();
        let Consentdt = new Date(this.cApp.subscriberPersonalDetails.ConsentDateTime);
        formattedDate = this.setDateFormat(Consentdt, true);
      }
      let formattedTime =''
      if(otherDetailForm.value.consentTime!='' && otherDetailForm.value.consentTime!=null){
        formattedTime = this.getTimeFormat(otherDetailForm.value.consentTime);
      }
      if(formattedDate!='' && formattedTime!=''){
        this.cApp.subscriberPersonalDetails.ConsentDateTime = formattedDate + ' ' + formattedTime;
      }
      this.cApp.subscriberPersonalDetails.consentZoneId = otherDetailForm.value.consentZoneId.toString();
    }
    if (this.typeofapplication == 2 && isSecondbuttonsubmit) {
      this.OpenProcessModal();
    }
    else {
      this.isloading = true;
      this.subscriberService.checkDuplicateUserByEmailId(this.cApp).subscribe(
        uData => {
          if (uData != null) {
            if (uData.length > 0) {
              this.cApp.subscriberApplicationHistory.userId = uData[0].id;
              this.cApp.subscriberPersonalDetails.customerId = uData[0].customerId;
              this.cApp.subscriberPersonalDetails.locationCode = uData[0].locationCode;
              this.cApp.subscriberPersonalDetails.locationId = uData[0].locationId;
              this.cApp.subscriberPersonalDetails.zone = uData[0].zoneId;
            }
          }

          if (this.cApp.subscriberPersonalDetails.customerId == "" || this.cApp.subscriberPersonalDetails.customerId == null) {
            this.cApp.subscriberPersonalDetails.customerId = "000000";
          }
          if (this.cApp.subscriberPersonalDetails.locationCode == "" || this.cApp.subscriberPersonalDetails.locationCode == null) {
            this.cApp.subscriberPersonalDetails.locationCode = "000000";
          }

          if (!this.isFrontEligibliityComplated) {
            this.subscriberService.manuallyApplicationSubmit(this.cApp).subscribe(data => {
              this.isloading = false;
              if (typeof (data) != 'undefined' && data != null) {
                if (data['user'] != null) {
                  if (data['user'].id > 0 && data['isEligiblityError'] == false && data['isEnroleError'] == false) {
                    this.resubmitApplicationHistoryId = 0;
                    if (sessionStorage.getItem('applicationHistoryId') != null) {
                      sessionStorage.removeItem('applicationHistoryId');
                    }
                    this.toastr.success('New user added successfully.', 'Success');
                    if (this.onlineRegistrationId > 0 && this.resubmitApplicationHistoryId == 0)
                      this.redirectToOnlineRegistrationList();
                    else
                      this.redirectToList();
                  }
                  else {
                    if (typeof (data['subscriberApplicationHistory'].subscriberApplicationHistory) != 'undefined')
                      this.toastr.success(data['subscriberApplicationHistory'].subscriberApplicationHistory.errorMessage, 'Error');
                    this.isEligiblityError = data.isEligiblityError;
                    this.isEnrolledError = data.isEnroleError;
                    if (this.isEligiblityError == true) {
                      this.eligiblityErrorMessage = data.eligibilityerror;
                      this.subscriberApplicationData = data.subscriberApplicationHistory;
                      this.badRequestError = data.badRequest;
                      this.toastr.error('Error in eligibility', 'Error');
                    }
                    if (this.isEnrolledError == true) {
                      this.toastr.error('Error in enrollment', 'Error');
                      this.enrolledErrorMessage = JSON.parse(data['applicationHistory'].enrollJSON);
                      sessionStorage.setItem('applicationHistoryId', data['subscriberApplicationHistory'].id);
                      sessionStorage.setItem('isFrontEligibliityComplated', 'false')
                      this.cApp.subscriberApplicationHistory = data['subscriberApplicationHistory'];
                    }
                  }
                }
              }
            },
              error => {
                this.isloading = false;
                console.log(error);
              }
            );
          }
          else {
            this.subscriberService.adminEnrollement(this.cApp).subscribe(data => {
              this.isloading = false;
              if (typeof (data) != 'undefined' && data != null) {
                if (data['user'] != null) {
                  if (data['user'].id > 0 && data['isEligiblityError'] == false && data['isEnroleError'] == false) {
                    this.resubmitApplicationHistoryId = 0;
                    if (sessionStorage.getItem('applicationHistoryId') != null) {
                      sessionStorage.removeItem('applicationHistoryId');
                    }
                    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
                      sessionStorage.removeItem('isOnlineApplicationHistoryId');
                    }
                    this.toastr.success('New user added successfully.', 'Success');
                    this.redirectToList();
                  }
                  else {
                    if (typeof (data['subscriberApplicationHistory'].subscriberApplicationHistory) != 'undefined')
                      this.toastr.success(data['subscriberApplicationHistory'].subscriberApplicationHistory.errorMessage, 'Error');
                    this.isEligiblityError = data.isEligiblityError;
                    this.isEnrolledError = data.isEnroleError;
                    if (this.isEligiblityError == true) {
                      this.eligiblityErrorMessage = data.eligibilityerror;
                      this.subscriberApplicationData = data.subscriberApplicationHistory;
                      this.badRequestError = data.badRequest;
                      this.toastr.error('Error in eligibility', 'Error');
                    }
                    if (this.isEnrolledError == true) {
                      this.toastr.error('Error in enrollment', 'Error');
                      this.enrolledErrorMessage = JSON.parse(data['applicationHistory'].enrollJSON);
                    }
                  }
                }
              }
            },
              error => {
                this.isloading = false;
                console.log(error);
              }
            );
          }
        },
        error => {
          this.isloading = false;
        }
      );
    }
  }


  getState() {
    this.stateService.GetStateListEBB().subscribe(data => {
      if (data) {
        this.statelist = data;
      }
    }, error => {
    });
  }

  showTempAddress(value) {

    if (value === 'Home') {
      this.fad.streetNumberTemp.setValidators([Validators.required]);
      //this.fad.aptNameTemp.setValidators([Validators.required]);
      this.fad.cityTemp.setValidators([Validators.required]);
      this.fad.stateIdTemp.setValidators([Validators.required]);
      this.fad.zipcodeTemp.setValidators([Validators.required]);
    }
    else {
      this.fad.streetNumberTemp.clearValidators();
      //this.fad.aptNameTemp.clearValidators();
      this.fad.cityTemp.clearValidators();
      this.fad.stateIdTemp.clearValidators();
      this.fad.zipcodeTemp.clearValidators();
    }
    this.fad.streetNumberTemp.updateValueAndValidity();
    //this.fad.aptNameTemp.updateValueAndValidity();
    this.fad.cityTemp.updateValueAndValidity();
    this.fad.stateIdTemp.updateValueAndValidity();
    this.fad.zipcodeTemp.updateValueAndValidity();
  }

  AddressFormInit() {
    this.addressForm = this.fb.group({
      streetNumber: ['', Validators.required],
      city: ['', Validators.required],
      stateId: ['', Validators.required],
      zipcode: ['', Validators.required],
      stateShortName: [''],
      stateShortNameTemp: [''],

      streetNumberTemp: [''],
      cityTemp: [''],
      stateIdTemp: [''],
      zipcodeTemp: [''],
      addressType: ['Mail'],
      isTribalLands: [this.isTribalLands]
    });


  }

  getStateName(id, isMainStateShortName) {
    if (id) {
      var state = this.statelist.filter((x: any) => x.id == id)[0];
      if (state) {
        if (isMainStateShortName) {
          //this.isTribalLands = state.isSelected
          this.stateShortNameValue = state.stateCode;
        }
        else {
          this.stateShortNameTempValue = state.stateCode;
        }
        return state.name;
      }
      return '';
    }
  }

  setShortName($event: any, isMainStateShortName: any) {
    this.getStateName($event.currentTarget.value, isMainStateShortName)
  }

  dependentFormInit() {
    this.dependentForm = this.fb.group({
      isDependent: [this.isDependent],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      dateofBirth: ['', Validators.required],
      sSN: [this.dependentSSNValue],
      tIN: [this.dependentTINValue]
      // sSN: [this.dependentSSNValue, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],	
      // tIN: [this.dependentTINValue, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]]
    });

    this.dependentForm.get('tIN').setValidators([]);
    this.dependentForm.get('tIN').updateValueAndValidity();
    this.dependentForm.get('tIN').setValue('');
  }

  onChangDependentSSN(type: string) {

    if (this.dependentTINValue == '' && this.dependentSSNValue == '') {
      this.dependentForm.get('tIN').enable();
      this.dependentForm.get('sSN').enable();
    }
    else if (this.dependentSSNValue != '' && this.dependentTINValue == '') {
      this.dependentForm.get('tIN').disable();
      this.dependentForm.get('sSN').enable();
    }
    else if (this.dependentSSNValue == '' && this.dependentTINValue != '') {
      this.dependentForm.get('tIN').enable();
      this.dependentForm.get('sSN').disable();
    }

    if (type == 'SSN' && this.dependentTINValue == '') {
      this.dependentForm.get('sSN').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      this.dependentForm.get('tIN').setValidators([]);
      this.dependentForm.get('sSN').updateValueAndValidity();
      this.dependentForm.get('tIN').updateValueAndValidity();
      this.dependentForm.get('tIN').setValue('');
    }
    else if (this.dependentSSNValue == '') {
      this.dependentForm.get('tIN').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
      this.dependentForm.get('sSN').setValidators([]);
      this.dependentForm.get('tIN').updateValueAndValidity();
      this.dependentForm.get('sSN').updateValueAndValidity();
      this.dependentForm.get('sSN').setValue('');
    }
  }

  enableDisableDependentFields(eventChecked) {
    this.isDependent = eventChecked;
    this.dependentForm.get('isDependent').setValue(this.isDependent);
    if (eventChecked) {
      this.dependentForm.enable();
    }
  }

  qualifyFormInit() {
    this.qualifyForm = this.fb.group({
      qualifyType: ['1'],
      sNAP: [], // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
      medicalId: [], // E1 = Medicaid
      sSI: [], // E3 = Supplemental Security Income (SSI)
      fPHA: [], // E4 = Federal Public Housing Assistance (FPHA)
      vP: [], // E15 = Veterans Pension or Survivors Benefit Programs
      //tSP: [], // Tribal Specific Programs
      bIA: [], // E8 = Bureau of Indian Affairs (BIA) General Assistance
      tribalTANF: [], // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
      fDPIR: [], // E10 = Food Distribution Program on Indian Reservations (FDPIR)
      tribalHS: [], // E11 = Tribal Head Start (only households that meet the income qualifying standard)
      eBI: [], // E13 = I don't participate in one of these programs, I want to qualify through my income.
      isTribalSpecific: false,
      pEASA: [], // E14 – Program Eligibility Approved by State Administrator 
      sLBA: [], // E50 – School Lunch/Breakfast Program
      fPG: [], // E51 – Federal Pell Grant
      sLOI: [], // E52 – Substantial Loss of Income
      eLIPCovid19: [], // E53 – Existing low-income program/COVID-19 program
      wIC: [], // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
      publicHousingCode:[''], // E4 = select other option display
    });
  }

  uncheckEBI($event, flag: string) {

    if (flag == 'SNAP') {
      this.isSNAPChecked = $event.currentTarget.checked;
      if (this.isSNAPChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'MedicalId') {
      this.isMedicalIdChecked = $event.currentTarget.checked;
      if (this.isMedicalIdChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'SSI') {
      this.isSSIChecked = $event.currentTarget.checked;
      if (this.isSSIChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'FPHA') {
      this.isFPHAChecked = $event.currentTarget.checked;
      if (this.isFPHAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'VP') {
      this.isVPChecked = $event.currentTarget.checked;
      if (this.isVPChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'BIA') {
      this.isBIAChecked = $event.currentTarget.checked;
      if (this.isBIAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'TribalTANF') {
      this.isTribalTANFChecked = $event.currentTarget.checked;
      if (this.isTribalTANFChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'FDPIR') {
      this.isFDPIRChecked = $event.currentTarget.checked;
      if (this.isFDPIRChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'TribalHS') {
      this.isTribalHSChecked = $event.currentTarget.checked;
      if (this.isTribalHSChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'pEASA') {
      this.isPEASAChecked = $event.currentTarget.checked;
      if (this.isPEASAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'sLBA') {
      this.isSLBAChecked = $event.currentTarget.checked;
      if (this.isSLBAChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'fPG') {
      this.isFPGChecked = $event.currentTarget.checked;
      if (this.isFPGChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'sLOI') {
      this.isSLOIChecked = $event.currentTarget.checked;
      if (this.isSLOIChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'eLIPCovid19') {
      this.isELIPCovid19Checked = $event.currentTarget.checked;
      if (this.isELIPCovid19Checked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'wIC') {
      this.isWICChecked = $event.currentTarget.checked;
      if (this.isWICChecked)
        this.isUncheckEBI = false;
    }
    else if (flag == 'EBI') {
      // this.isUncheckEBI = $event.currentTarget.checked;
      if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
        && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
        && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
        && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
      ) {
        this.isUncheckEBI = $event.currentTarget.checked;
      }
      else {
        this.isUncheckEBI = false;
        this.qualifyForm.setValue({
          qualifyType: ['1'],
          sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
          medicalId: this.isMedicalIdChecked, // E1 = Medicaid
          sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
          fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
          vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
          //tSP: [], // Tribal Specific Programs
          bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
          tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
          fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
          tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
          eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
          isTribalSpecific: false,
          pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
          sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
          fPG: this.isFPGChecked, // E51 – Federal Pell Grant
          sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
          eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
          wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
          publicHousingCode:this.publicHousingCodeValue
        });
      }
    }
    if (!this.isFPHAChecked) {
      this.publicHousingCodeValue = '';
      this.optionA = false;
      this.optionB = false;
      this.optionC = false;
      this.optionD = false;
    }
  }

  otherDetailFormInit() {
    if(this.enableConsentDateTime){
      this.otherDetailForm = this.fb.group({
        enrollmentDate: [''],
        applicationNumber: ['', Validators.required],
        accountNumber: [''],
        locationId: [],
        zone: [],
        transactionEffectiveDate: [''],
        serviceInitializationDate: [''],
        eligibilityExpirationDate: [''],
        isNewExistingUser: ['New'],
        isConsumerFee: [true],
        consentDateTime: ['', Validators.required],
        consentTime: ['', Validators.required],
        consentZoneId: [''],
      });
    }
    else{
      this.otherDetailForm = this.fb.group({
        enrollmentDate: [''],
        applicationNumber: ['', Validators.required],
        accountNumber: [''],
        locationId: [],
        zone: [],
        transactionEffectiveDate: [''],
        serviceInitializationDate: [''],
        eligibilityExpirationDate: [''],
        isNewExistingUser: ['New'],
        isConsumerFee: [true]
      });
    }
    
  }

  getProviderLocation(): void {
    this.locationService.getProviderLocationDropdown(this.providerId).subscribe(
      data => {
        if (data != null) {
          for (let iCounter = 0; iCounter < data.length; iCounter++) {
            this.locations.push({ 'id': data[iCounter].id, 'itemName': data[iCounter].name })
          }
        }
      }
    );
  }

  // Location

  onLocationSelect(item) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    if (this.locationList != undefined) {
      this.locationList = this.removeElemetninArray(this.locationList, item['id']);
    }
    this.locationList.push(item['id']);
    this.getProviderZoneDropDown(this.locationList.join(','));
  }

  onLocationDeSelect(item) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    if (this.locationList != undefined) {
      this.locationList = this.removeElemetninArray(this.locationList, item['id']);
    }
    this.getProviderZoneDropDown(this.locationList.join(','));
  }

  onLocationSelectAll(items) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.locationList.push(items[iCounter]['id']);
    }
    this.getProviderZoneDropDown(this.locationList.join(','));
  }

  onLocationDeSelectALL(items) {
    this.locationList = [];
    this.zoneList = []
    this.selectedZoneItems = []
    this.getProviderZoneDropDown(this.locationList.join(','));
  }

  getProviderZoneDropDown(id): void {
    this.providerZone.getProviderZoneDropDownByLocationId(this.providerId, this.userId, id).subscribe(
      data => {
        this.listOfZone = data;
        this.dropdownZoneList = [];
        if (this.listOfZone != null) {
          if (this.listOfZone.length > 0) {
            for (let iCounter = 0; iCounter < this.listOfZone.length; iCounter++) {
              this.dropdownZoneList.push(
                { "id": this.listOfZone[iCounter].id, "itemName": this.listOfZone[iCounter].zoneCode }
              );
            }
          }
        }


      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }

  // Zone 

  onZoneSelect(item) {
    this.zoneList = [];
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
    this.zoneList.push(item['id']);
  }

  onZoneDeSelect(item) {
    this.zoneList = [];
    if (this.zoneList != undefined) {
      this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
    }
  }

  onZoneSelectAll(items) {
    this.zoneList = [];
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.zoneList.push(items[iCounter]['id']);
    }
  }

  onZoneDeSelectAll(item) {
    this.zoneList = [];
  }

  setDateFormat(dateArray: any, isYYYYMMDDWithDash) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      if (isYYYYMMDDWithDash)
        return dateArray.getFullYear() + '-' + mm1 + '-' + dd1;
      else
        return mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      return null;
  }

  redirectToOnlineRegistrationList() {
    if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
      sessionStorage.removeItem('isOnlineApplicationHistoryId');
    }
    this.router.navigate(['admin/online/register/user']);
  }

  GetOnlineRegistrationFindById() {
    this.onlineRegisterService.GetOnlineRegistrationFindById(this.onlineRegistrationId).subscribe(
      data => {
        if (data != null) {
          this.stateShortNameValue = data.stateCode;
          this.stateShortNameTempValue = data.mailStateCode;
          this.isAgree = data.ebbpEligibilityCheck;
          this.personalDetailsForm.patchValue(data);
          this.personalDetailsForm.get('phoneNo').setValue(data.phone);
          this.personalDetailsForm.get('sSN').setValue(data.ssn);
          this.personalDetailsForm.get('tIN').setValue(data.tin);
          this.tinValue = data.tin == null ? '' : data.tin;
          this.ssnValue = data.ssn == null ? '' : data.ssn;
          this.onChangeSSN(data.ssn ? 'SSN' : 'TIN');
          this.addressForm.patchValue(data);
          this.addressForm.get('addressType').setValue(data.isTemporaryAddress ? 'Home' : 'Mail')
          this.addressForm.get('isTribalLands').setValue(data.onTribalLand)
          this.addressForm.get('stateShortName').setValue(this.stateShortNameValue)
          this.addressForm.get('stateShortNameTemp').setValue(this.stateShortNameTempValue)
          this.isTribalLands = data.onTribalLand;
          this.isDependent = data.isDependent == null ? false : data.isDependent;
          this.globle.cApp.subscriberDependent.isDependent = this.isDependent;
          this.dependentForm.get('firstName').setValue(data.depenentFirstName);
          this.dependentForm.get('middleName').setValue(data.depenentMiddleName);
          this.dependentForm.get('lastName').setValue(data.depenentLastName);
          this.dependentForm.get('dateofBirth').setValue(data.depenentDateofBirth);
          this.dependentForm.get('sSN').setValue(data.depenentSSN);
          this.dependentForm.get('tIN').setValue(data.depenentTIN);
          this.dependentSSNValue = data.depenentSSN == null ? '' : data.depenentSSN;
          this.dependentTINValue = data.depenentTIN == null ? '' : data.depenentTIN;
          this.onChangDependentSSN(data.depenentSSN ? 'SSN' : 'TIN');
          this.qualifyForm.get('sNAP').setValue(data.snap);
          this.qualifyForm.get('medicalId').setValue(data.medicald);
          this.qualifyForm.get('sSI').setValue(data.ss);
          this.qualifyForm.get('fPHA').setValue(data.fpha);
          this.qualifyForm.get('vP').setValue(data.vpspb);
          this.qualifyForm.get('bIA').setValue(data.biaga);
          this.qualifyForm.get('tribalTANF').setValue(data.ttanf);
          this.qualifyForm.get('fDPIR').setValue(data.fdpir);
          this.qualifyForm.get('tribalHS').setValue(data.hs);
          this.qualifyForm.get('eBI').setValue(data.ebi);
          //  this.qualifyForm.get('pEASA').setValue(data.depenentTIN);
          this.qualifyForm.get('sLBA').setValue(data.slbp);
          this.qualifyForm.get('fPG').setValue(data.fpg);
          this.qualifyForm.get('sLOI').setValue(data.sli);
          this.qualifyForm.get('wIC').setValue(data.wic);
          this.otherDetailForm.get('applicationNumber').setValue(data.nladApplicationNumber);
          this.isSNAPChecked = data.snap;
          this.isMedicalIdChecked = data.medicald;
          this.isSSIChecked = data.ss;
          this.isFPHAChecked = data.fpha;
          this.isVPChecked = data.vpspb;
          this.isBIAChecked = data.biaga;
          this.isTribalTANFChecked = data.ttanf;
          this.isFDPIRChecked = data.fdpir;
          this.isTribalHSChecked = data.hs;
          this.isSLBAChecked = data.slbp;
          this.isSLOIChecked = data.fpg;
          this.isFPGChecked = data.sli;
          this.isWICChecked = data.wic;
          
          this.publicHousingCodeValue=data.publicHousingCode;

          if (data.snap || data.medicald || data.ss || data.fpha || data.vpspb || data.biaga ||
            data.ttanf || data.fdpir || data.hs || data.ebi || data.slbp ||
            data.fpg || data.sli || data.wic) {
            this.isUncheckEBI = false;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  confirmReasonFormInit() {
    this.confirmReasonForm = this.fb.group({
      isEnrolledInNLAD: ['No'],
    });
  }

  showhideOtherInformation(value) {
    if (value === 'Yes') {
      this.isAlreadyEnrolledRadioChecked = true;
      this.odf.enrollmentDate.setValidators([Validators.required]);
      this.odf.applicationNumber.setValidators([Validators.required]);
      //this.odf.accountNumber.setValidators([Validators.required]);
      this.odf.transactionEffectiveDate.setValidators([Validators.required]);
      this.odf.serviceInitializationDate.setValidators([Validators.required]);
      this.odf.eligibilityExpirationDate.setValidators([Validators.required]);
      this.isTransferButtonShow = false;
      this.typeofapplication = 2;
      this.OpenModel();
    }
    else if (value === 'Transfer') {
      this.isAlreadyEnrolledRadioChecked = true;
      this.odf.enrollmentDate.setValidators([Validators.required]);
    //  this.odf.applicationNumber.setValidators([Validators.required]);
      //this.odf.accountNumber.setValidators([Validators.required]);
      this.odf.transactionEffectiveDate.setValidators([Validators.required]);
      this.odf.serviceInitializationDate.setValidators([Validators.required]);
      this.odf.eligibilityExpirationDate.setValidators([Validators.required]);
      if(this.isApplicationidValue){
        this.odf.applicationNumber.setValidators([Validators.required]);      
      }
      else{
        this.odf.applicationNumber.clearValidators();      
      }
      this.odf.applicationNumber.updateValueAndValidity();
      this.typeofapplication = 3;
      this.isTransferButtonShow = true;
    }
    else {
      this.isAlreadyEnrolledRadioChecked = false;
      this.odf.enrollmentDate.clearValidators();
      this.odf.applicationNumber.clearValidators();
      //this.odf.accountNumber.clearValidators();
      this.odf.transactionEffectiveDate.clearValidators();
      this.odf.serviceInitializationDate.clearValidators();
      this.odf.eligibilityExpirationDate.clearValidators();
      this.typeofapplication = 1;
      this.isTransferButtonShow = false;
    }
    this.odf.enrollmentDate.updateValueAndValidity();
    this.odf.applicationNumber.updateValueAndValidity();
    //this.odf.accountNumber.updateValueAndValidity();
    this.odf.transactionEffectiveDate.updateValueAndValidity();
    this.odf.serviceInitializationDate.updateValueAndValidity();
    this.odf.eligibilityExpirationDate.updateValueAndValidity();
  }

  checkIsAgree($event) {
    this.isAgree = $event.currentTarget.checked;
  }

  checkTribalLands($event) {
    this.isTribalLands = $event.currentTarget.checked;
  }

  GetResumbitApplicationData() {
    this.subscriberService.reSubmitApplicationFindByApplicationHistoryId(this.resubmitApplicationHistoryId).subscribe(
      data1 => {
        let data = data1.resubmitModel;

        if (data != null) {
          this.cApp.subscriberApplicationHistory.id = data.applicationHistoryModel.id;
          this.cApp.subscriberApplicationHistory.accessKey = data.applicationHistoryModel.accessKey;
          this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = '';
          this.cApp.subscriberPersonalDetails.transactionEffectiveDate = '';
          this.cApp.subscriberPersonalDetails.serviceInitializationDate = '';
          this.cApp.subscriberPersonalDetails.onlineRegistrationID = data.applicationHistoryModel.onlineRegistrationId;
          this.onlineRegistrationId = data.applicationHistoryModel.onlineRegistrationId == null ? "0" : data.applicationHistoryModel.onlineRegistrationId;
          this.cApp.subscriberApplicationHistory.userId = data.applicationHistoryModel.userId;
          this.cApp.subscriberApplicationHistory.applicationId = data.applicationHistoryModel.applicationId;

          let chkapplicationidVal=false;
          if(data.applicationHistoryModel.isTransferAppno){
            chkapplicationidVal=true;
            this.isApplicationidValue=true;
          }
          else{
            chkapplicationidVal=false;
            this.isApplicationidValue=false;
          }

          this.personalDetailsForm.setValue({
            firstName: data.postEnrollJSON.firstName,
            middleName: data.postEnrollJSON.middleName,
            lastName: data.postEnrollJSON.lastName,
            phoneNo: data.postEnrollJSON.phoneNumber,
            dateofBirth: data.dob,
            emailId: data.postEnrollJSON.consumerEmail,
            sSN: data.postEnrollJSON.last4ssn,
            tIN: data.postEnrollJSON.tribalId,
            chkAgreement: true,
            chkapplicationid:chkapplicationidVal
          });
        }
        if (data.postEnrollJSON.last4ssn != null) {
          this.ssnValue = data.postEnrollJSON.last4ssn;
          this.tinValue = '';
          //this.onChangeSSN('SSN')
        }
        else {
          this.ssnValue = '';
          this.tinValue = data.postEnrollJSON.tribalId;
          //this.onChangeSSN('')
        }

        this.stateShortNameValue = data.stateModel.stateCode
        this.stateShortNameTempValue = data.mailingStateModel.stateCode;
        this.addressForm.setValue({
          streetNumber: data.postEnrollJSON.primaryAddress1,
          city: data.postEnrollJSON.primaryCity,
          stateId: data.stateModel.id,
          zipcode: data.postEnrollJSON.primaryZipCode,
          stateShortName: this.stateShortNameValue,
          stateShortNameTemp: this.stateShortNameTempValue,

          streetNumberTemp: data.postEnrollJSON.mailingAddress1,
          cityTemp: data.postEnrollJSON.mailingCity,
          stateIdTemp: data.mailingStateModel.id,
          zipcodeTemp: data.postEnrollJSON.mailingZipCode,
          addressType: data.postEnrollJSON.primaryAddress1 == data.postEnrollJSON.mailingAddress1 ? 'Mail' : 'Home',
          isTribalLands: data.postEnrollJSON.ebbpTribalBenefitFlag == "1" ? true : false
        });

        this.isTribalLands = data.postEnrollJSON.ebbpTribalBenefitFlag == "1" ? true : false;
        this.isDependent = data.postEnrollJSON.bqpFirstName != '' ? true : false;
        this.globle.cApp.subscriberDependent.isDependent = this.isDependent;        
        this.dependentForm.get('isDependent').setValue(this.isDependent);

        if (this.isDependent) {
          this.dependentForm.enable();
        }

        this.dependentSSNValue = data.postEnrollJSON.bqpLast4ssn;
        this.dependentTINValue = data.postEnrollJSON.bqpTribalId;
        this.dependentForm = this.fb.group({
          isDependent: [this.isDependent],
          firstName: data.postEnrollJSON.bqpFirstName,
          middleName: data.postEnrollJSON.bqpMiddleName,
          lastName: data.postEnrollJSON.bqpLastName,
          dateofBirth: data.bqpDob,
          sSN: this.dependentSSNValue,
          tIN: this.dependentTINValue
        });
        if (data.postEnrollJSON.bqpLast4ssn != null) {
          this.dependentSSNValue = data.postEnrollJSON.bqpLast4ssn;
          this.dependentTINValue = '';
          this.onChangDependentSSN('SSN')
        }
        else {
          this.dependentSSNValue = '';
          this.dependentTINValue = data.postEnrollJSON.bqpTribalId;
          this.onChangDependentSSN('')
        }

        this.publicHousingCodeValue=data.postEnrollJSON.publicHousingCode?data.postEnrollJSON.publicHousingCode:"";

        // Set Qualify Program
        if (data.qualifyModel.snap == 'true') {
          this.isSNAPChecked = true;
          if (this.isSNAPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.medicald == 'true') {
          this.isMedicalIdChecked = true;
          if (this.isMedicalIdChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.ssi == 'true') {
          this.isSSIChecked = true;
          if (this.isSSIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fpha == 'true') {
          this.isFPHAChecked = true;
          if (this.isFPHAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.vp == 'true') {
          this.isVPChecked = true;
          if (this.isVPChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.bia == 'true') {
          this.isBIAChecked = true;
          if (this.isBIAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.tribalTANF == 'true') {
          this.isTribalTANFChecked = true;
          if (this.isTribalTANFChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fdpir == 'true') {
          this.isFDPIRChecked = true;
          if (this.isFDPIRChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.tribalHS == 'true') {
          this.isTribalHSChecked = true;
          if (this.isTribalHSChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.peasa == 'true') {
          this.isPEASAChecked = true;
          if (this.isPEASAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.slba == 'true') {
          this.isSLBAChecked = true;
          if (this.isSLBAChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.fpg == 'true') {
          this.isFPGChecked = true;
          if (this.isFPGChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.sloi == 'true') {
          this.isSLOIChecked = true;
          if (this.isSLOIChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.elipCovid19 == 'true') {
          this.isELIPCovid19Checked = true;
          if (this.isELIPCovid19Checked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.wic == 'true') {
          this.isWICChecked = true;
          if (this.isWICChecked)
            this.isUncheckEBI = false;
        }
        else if (data.qualifyModel.ebi == 'true') {
          // this.isUncheckEBI = $event.currentTarget.checked;
          if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
            && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
            && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
            && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
          ) {
            this.isUncheckEBI = true;
          }
          else {
            this.isUncheckEBI = false;
          }
        }

        this.qualifyForm.setValue({
          qualifyType: '1',
          sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
          medicalId: this.isMedicalIdChecked, // E1 = Medicaid
          sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
          fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
          vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
          //tSP: [], // Tribal Specific Programs
          bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
          tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
          fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
          tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
          eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
          isTribalSpecific: false,
          pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
          sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
          fPG: this.isFPGChecked, // E51 – Federal Pell Grant
          sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
          eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
          wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
          publicHousingCode:this.publicHousingCodeValue
        });
       
        this.cApp.subscriberPersonalDetails.applicationNumber = data.applicationHistoryModel.apiApplicationId;

        if (data.applicationHistoryModel.isNewExistingUser != '' && data.applicationHistoryModel.isNewExistingUser != null)
          this.isNewExistingUser = data.applicationHistoryModel.isNewExistingUser;

        let applicationStatus = data.applicationHistoryModel.enrollStatus;
        let enrollmentdate = null;
        let serviceInitializationDate = null;
        if (applicationStatus == 'Subscriber transfer pending') {
          enrollmentdate = data.applicationHistoryModel.enrollmentDate;
          serviceInitializationDate = data.applicationHistoryModel.complateStatusDate;
          this.showhideOtherInformation('Transfer');
        }

        let applicationId = "";
        if (data.applicationHistoryModel.apiApplicationId != '' && data.applicationHistoryModel.apiApplicationId != null) {
          applicationId = data.applicationHistoryModel.apiApplicationId;
        }

        if (this.enableConsentDateTime) {
          let consentDatetime = '';
          if (data.postEnrollJSON.consentDateTime != null && data.postEnrollJSON.consentDateTime != '') {
            consentDatetime = data.postEnrollJSON.consentDateTime;
          }
          let consentZoneId = '';
          if (data.postEnrollJSON.consentTimeZoneId != null && data.postEnrollJSON.consentTimeZoneId != '') {
            consentZoneId = data.postEnrollJSON.consentTimeZoneId;
          }

          this.otherDetailForm.setValue({
            enrollmentDate: enrollmentdate,
            applicationNumber: applicationId,
            accountNumber: data.applicationHistoryModel.accountNumber == null ? '' : data.applicationHistoryModel.accountNumber,
            locationId: null,
            zone: null,
            transactionEffectiveDate: null,
            serviceInitializationDate: serviceInitializationDate,
            eligibilityExpirationDate: null,
            isNewExistingUser: this.isNewExistingUser,
            isConsumerFee: (data.postEnrollJSON.consumerFee == null || data.postEnrollJSON.consumerFee == "1") ? true : false,
            consentDateTime: consentDatetime,
            consentTime: '',
            consentZoneId: consentZoneId
          });

          if (data.postEnrollJSON.consentDateTime != null && data.postEnrollJSON.consentDateTime != '') {
            this.otherDetailForm.get('consentTime').setValue(data.postEnrollJSON.consentDateTime);
          }
        }
        else {
          this.otherDetailForm.setValue({
            enrollmentDate: enrollmentdate,
            applicationNumber: applicationId,
            accountNumber: data.applicationHistoryModel.accountNumber == null ? '' : data.applicationHistoryModel.accountNumber,
            locationId: null,
            zone: null,
            transactionEffectiveDate: null,
            serviceInitializationDate: serviceInitializationDate,
            eligibilityExpirationDate: null,
            isNewExistingUser: this.isNewExistingUser,
            isConsumerFee: (data.postEnrollJSON.consumerFee == null || data.postEnrollJSON.consumerFee == "1") ? true : false
          });
        }

        this.locationList.push(data.applicationHistoryModel.locationId)
        this.zoneList.push(data.applicationHistoryModel.zoneId);
     
        this.selectedZoneItems = [];
        this.selectedLocation = [];
        this.selectedZoneItems.push({ 'id': data.applicationHistoryModel.zoneId, 'itemName': data.applicationHistoryModel.zoneName });
        this.selectedLocation.push({ 'id': data.applicationHistoryModel.locationId, 'itemName': data.applicationHistoryModel.locationName });
        if (data.applicationHistoryModel.apiStatus != "COMPLETE" && data.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          this.isEligiblityError = true;
          if (this.isEligiblityError == true) {
            this.eligiblityErrorMessage = data1.eligibilityerror;
            this.subscriberApplicationData = data1.resubmitModel.applicationHistoryModel;
            this.badRequestError = data1.badRequest;
          }
        }
        else if (data.applicationHistoryModel.apiStatus == "COMPLETE" && data.applicationHistoryModel.apiStatus != "PENDING_CERT") {
          this.isEnrolledError = true;
          if (this.isEnrolledError == true) {
            this.enrolledErrorMessage = JSON.parse(data1.resubmitModel.applicationHistoryModel.enrollJSON);
          }
        }

      },
      error => {
        console.log(error);
      }
    );
  }
  isMultipleQualifyProgramSelected() {
    let isMultiple = false;
    if (this.isSNAPChecked && (this.isMedicalIdChecked || this.isSSIChecked || this.isFPHAChecked
      || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isMedicalIdChecked && (this.isSNAPChecked || this.isSSIChecked || this.isFPHAChecked
      || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isSSIChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isFPHAChecked
      || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isFPHAChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isVPChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isBIAChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isBIAChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isTribalTANFChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isTribalTANFChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isFDPIRChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isFDPIRChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isTribalHSChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isPEASAChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isPEASAChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isSLBAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isSLBAChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isPEASAChecked || this.isFPGChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isFPGChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked
      || this.isSLOIChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isSLOIChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked
      || this.isFPGChecked || this.isELIPCovid19Checked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isELIPCovid19Checked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked
      || this.isFPGChecked || this.isSLOIChecked || this.isWICChecked)) {
      isMultiple = true;
    }
    else if (this.isWICChecked && (this.isSNAPChecked || this.isMedicalIdChecked || this.isSSIChecked
      || this.isFPHAChecked || this.isVPChecked || this.isBIAChecked || this.isTribalTANFChecked
      || this.isFDPIRChecked || this.isTribalHSChecked || this.isPEASAChecked || this.isSLBAChecked
      || this.isFPGChecked || this.isSLOIChecked || this.isELIPCovid19Checked)) {
      isMultiple = true;
    }

    return isMultiple;
  }

  setNewExistingUser(val: string) {
    this.isNewExistingUser = val;
  }

  GetEligibilityApplicationFindByApplicationHistoryId() {
    this.subscriberService.GetEligibilityApplicationFindByApplicationHistoryId(this.onlineApplicationHistoryId).subscribe(
      data1 => {
        
        let data = data1.resubmitModel.eligibllityModel;
        if (data != null) {          
          this.cApp.subscriberApplicationHistory.id = data1.resubmitModel.enrollModel.applicationHistoryModel.id;
          this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = '';
          this.cApp.subscriberPersonalDetails.transactionEffectiveDate = '';
          this.cApp.subscriberPersonalDetails.serviceInitializationDate = '';
          this.cApp.subscriberPersonalDetails.onlineRegistrationID = data1.resubmitModel.enrollModel.applicationHistoryModel.onlineRegistrationId;
          this.onlineRegistrationId = data1.resubmitModel.enrollModel.applicationHistoryModel.onlineRegistrationId;
          this.cApp.subscriberApplicationHistory.userId = data.subscriberApplicationHistory.userId;
          this.cApp.subscriberApplicationHistory.applicationId = data.subscriberApplicationHistory.applicationId;

          let chkapplicationidVal=false;
          if(data1.resubmitModel.enrollModel.applicationHistoryModel.isTransferAppno){
            chkapplicationidVal=true;
            this.isApplicationidValue=true;
          }
          else{
            chkapplicationidVal=false;
            this.isApplicationidValue=false;
          }

          this.personalDetailsForm.setValue({
            firstName: data.subscriberPersonalDetails.firstName,
            middleName: data.subscriberPersonalDetails.middleName,
            lastName: data.subscriberPersonalDetails.lastName,
            phoneNo: data.subscriberPersonalDetails.phoneNo,
            dateofBirth: data.subscriberPersonalDetails.dateofBirth,
            emailId: data.subscriberPersonalDetails.emailId,
            sSN: data.subscriberPersonalDetails.ssn,
            tIN: data.subscriberPersonalDetails.tin,
            chkAgreement: true,
            chkapplicationid:chkapplicationidVal
          });

          if (data.subscriberPersonalDetails.ssn != null) {
            this.ssnValue = data.subscriberPersonalDetails.ssn;
            this.tinValue = '';
            //this.onChangeSSN('SSN')
          }
          else {
            this.ssnValue = '';
            this.tinValue = data.subscriberPersonalDetails.tin;
            //this.onChangeSSN('')
          }

          this.stateShortNameValue = data.subscriberAddress.stateShortName;
          this.stateShortNameTempValue = data.subscriberAddress.stateShortNameTemp;
          this.addressForm.setValue({
            streetNumber: data.subscriberAddress.streetNumber,
            city: data.subscriberAddress.city,
            stateId: data.subscriberAddress.stateId,
            zipcode: data.subscriberAddress.zipcode,
            stateShortName: this.stateShortNameValue,
            stateShortNameTemp: this.stateShortNameTempValue,

            streetNumberTemp: data.subscriberAddress.stateShortNameTemp,
            cityTemp: data.subscriberAddress.cityTemp,
            stateIdTemp: data.subscriberAddress.stateIdTemp,
            zipcodeTemp: data.subscriberAddress.zipcodeTemp,
            addressType: data.subscriberAddress.addressType == data.subscriberAddress.addressType ? 'Mail' : 'Home',
            isTribalLands: data.subscriberAddress.isTribalLands
          });
          this.isTribalLands = data.subscriberAddress.isTribalLands;
          this.isDependent = data.subscriberDependent.firstName != '' ? true : false;
          this.globle.cApp.subscriberDependent.isDependent = this.isDependent;
          this.dependentForm.get('isDependent').setValue(this.isDependent);
          if (this.isDependent) {
            this.dependentForm.enable();
          }
          this.dependentSSNValue = data.subscriberDependent.ssn;
          this.dependentTINValue = data.subscriberDependent.tin;
          this.dependentForm = this.fb.group({
            isDependent: [this.isDependent],
            firstName: data.subscriberDependent.firstName,
            middleName: data.subscriberDependent.middleName,
            lastName: data.subscriberDependent.lastName,
            dateofBirth: data.subscriberDependent.dateofBirth,
            sSN: this.dependentSSNValue,
            tIN: this.dependentTINValue
          });
          if (data.subscriberDependent.ssn != null) {
            this.dependentSSNValue = data.subscriberDependent.ssn;
            this.dependentTINValue = '';
            //this.onChangDependentSSN('SSN')
          }
          else {
            this.dependentSSNValue = '';
            this.dependentTINValue = data.subscriberDependent.tin;
            //this.onChangDependentSSN('')
          }

          this.publicHousingCodeValue=data.subscriberQualify.publicHousingCode?data.subscriberQualify.publicHousingCode:"";

          if (this.publicHousingCodeValue != "") {
            let phc = this.publicHousingCodeValue;
              if (phc == "1") {
                this.optionA=true;
                this.optionB=false;
                this.optionC=false;
                this.optionD=false;
              }
              else if (phc == "2") {
                this.optionA=false;
                this.optionB=true;
                this.optionC=false;
                this.optionD=false;
              }
              else if (phc == "3") {
               this.optionA=false;
                this.optionB=false;
                this.optionC=true;
                this.optionD=false;
              }
              else if (phc == "4") {
                this.optionA=false;
                this.optionB=false;
                this.optionC=false;
                this.optionD=true;
              }
          }
  
          // Set Qualify Program        
          if (data.subscriberQualify.snap) {
            this.isSNAPChecked = true;
            if (this.isSNAPChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.medicald) {
            this.isMedicalIdChecked = true;
            if (this.isMedicalIdChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.ssi) {
            this.isSSIChecked = true;
            if (this.isSSIChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.fpha) {
            this.isFPHAChecked = true;
            if (this.isFPHAChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.vp) {
            this.isVPChecked = true;
            if (this.isVPChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.bia) {
            this.isBIAChecked = true;
            if (this.isBIAChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.tribalTANF) {
            this.isTribalTANFChecked = true;
            if (this.isTribalTANFChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.fdpir) {
            this.isFDPIRChecked = true;
            if (this.isFDPIRChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.tribalHS) {
            this.isTribalHSChecked = true;
            if (this.isTribalHSChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.peasa) {
            this.isPEASAChecked = true;
            if (this.isPEASAChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.lba) {
            this.isSLBAChecked = true;
            if (this.isSLBAChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.fpg) {
            this.isFPGChecked = true;
            if (this.isFPGChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.sloi) {
            this.isSLOIChecked = true;
            if (this.isSLOIChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.elipCovid19) {
            this.isELIPCovid19Checked = true;
            if (this.isELIPCovid19Checked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.wic) {
            this.isWICChecked = true;
            if (this.isWICChecked)
              this.isUncheckEBI = false;
          }
          else if (data.subscriberQualify.ebi) {
            // this.isUncheckEBI = $event.currentTarget.checked;
            if (!this.isSNAPChecked && !this.isMedicalIdChecked && !this.isSSIChecked && !this.isFPHAChecked
              && !this.isVPChecked && !this.isBIAChecked && !this.isTribalTANFChecked && !this.isFDPIRChecked
              && !this.isTribalHSChecked && !this.isPEASAChecked && !this.isSLBAChecked && !this.isFPGChecked
              && !this.isSLOIChecked && !this.isELIPCovid19Checked && !this.isWICChecked
            ) {
              this.isUncheckEBI = true;
            }
            else {
              this.isUncheckEBI = false;
            }
          }

          this.qualifyForm.setValue({
            qualifyType: '1',
            sNAP: this.isSNAPChecked, // E2 = Supplemental Nutrition Assistance Program (SNAP) (Food Stamps)
            medicalId: this.isMedicalIdChecked, // E1 = Medicaid
            sSI: this.isSSIChecked, // E3 = Supplemental Security Income (SSI)
            fPHA: this.isFPHAChecked, // E4 = Federal Public Housing Assistance (FPHA)
            vP: this.isVPChecked, // E15 = Veterans Pension or Survivors Benefit Programs
            //tSP: [], // Tribal Specific Programs
            bIA: this.isBIAChecked, // E8 = Bureau of Indian Affairs (BIA) General Assistance
            tribalTANF: this.isTribalTANFChecked, // E9 = Tribal Temporary Assistance for Needy Families (Tribal TANF)
            fDPIR: this.isFDPIRChecked, // E10 = Food Distribution Program on Indian Reservations (FDPIR)
            tribalHS: this.isTribalHSChecked, // E11 = Tribal Head Start (only households that meet the income qualifying standard)
            eBI: this.isUncheckEBI, // E13 = I don't participate in one of these programs, I want to qualify through my income.
            isTribalSpecific: false,
            pEASA: this.isPEASAChecked, // E14 – Program Eligibility Approved by State Administrator 
            sLBA: this.isSLBAChecked, // E50 – School Lunch/Breakfast Program
            fPG: this.isFPGChecked, // E51 – Federal Pell Grant
            sLOI: this.isSLOIChecked, // E52 – Substantial Loss of Income
            eLIPCovid19: this.isELIPCovid19Checked, // E53 – Existing low-income program/COVID-19 program
            wIC: this.isWICChecked, // E54 – Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
            publicHousingCode:this.publicHousingCodeValue
          });
        

          if (data1.resubmitModel.enrollModel.applicationHistoryModel.isNewExistingUser != '' && data1.resubmitModel.enrollModel.applicationHistoryModel.isNewExistingUser != null)
            this.isNewExistingUser = data1.resubmitModel.enrollModel.applicationHistoryModel.isNewExistingUser;


          let applicationStatus = data1.resubmitModel.enrollModel.applicationHistoryModel.enrollStatus;
          let enrollmentdate = null;
          let serviceInitializationDate = null;
          if (applicationStatus == 'Subscriber transfer pending') {
            enrollmentdate = data1.resubmitModel.enrollModel.applicationHistoryModel.enrollmentDate;
            serviceInitializationDate = data1.resubmitModel.enrollModel.applicationHistoryModel.complateStatusDate;
            this.showhideOtherInformation('Transfer');
          }

          if (this.enableConsentDateTime) {
            let consentDatetime = '';
            if (data.subscriberPersonalDetails.consentDateTime != null && data.subscriberPersonalDetails.consentDateTime != '') {
              consentDatetime = data.subscriberPersonalDetails.consentDateTime;
            }

            let consentZoneid = '';
            if (data.subscriberPersonalDetails.consentZoneId != null && data.subscriberPersonalDetails.consentZoneId != '') {
              consentZoneid = data.subscriberPersonalDetails.consentZoneId;
            }

            this.otherDetailForm.setValue({
              enrollmentDate: enrollmentdate,
              applicationNumber: data1.resubmitModel.enrollModel.applicationHistoryModel.apiApplicationId,
              accountNumber: data1.resubmitModel.enrollModel.applicationHistoryModel.acoountNumber,
              locationId: null,
              zone: null,
              transactionEffectiveDate: null,
              serviceInitializationDate: serviceInitializationDate,
              eligibilityExpirationDate: null,
              isNewExistingUser: this.isNewExistingUser,
              isConsumerFee: (data.subscriberPersonalDetails.isConsumerFee == null || data.subscriberPersonalDetails.isConsumerFee == true) ? true : false,
              consentDateTime: consentDatetime,
              consentTime: '',
              consentZoneId: consentZoneid
            });

            if (data.subscriberPersonalDetails.consentDateTime != null && data.subscriberPersonalDetails.consentDateTime != '') {
              this.otherDetailForm.get('consentTime').setValue(data.subscriberPersonalDetails.consentDateTime);
            }
          }
          else {
            this.otherDetailForm.setValue({
              enrollmentDate: enrollmentdate,
              applicationNumber: data1.resubmitModel.enrollModel.applicationHistoryModel.apiApplicationId,
              accountNumber: data1.resubmitModel.enrollModel.applicationHistoryModel.acoountNumber,
              locationId: null,
              zone: null,
              transactionEffectiveDate: null,
              serviceInitializationDate: serviceInitializationDate,
              eligibilityExpirationDate: null,
              isNewExistingUser: this.isNewExistingUser,
              isConsumerFee: (data.subscriberPersonalDetails.isConsumerFee == null || data.subscriberPersonalDetails.isConsumerFee == true) ? true : false
            });
          }
    
          if (data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "COMPLETE" && data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "PENDING_CERT") {
            this.isEligiblityError = true;
            if (this.isEligiblityError == true) {
              this.eligiblityErrorMessage = data1.eligibilityerror;
              this.subscriberApplicationData = data1.resubmitModel.enrollModel.applicationHistoryModel;
              this.badRequestError = data1.badRequest;
            }
          }
          else if (data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus == "COMPLETE" && data1.resubmitModel.enrollModel.applicationHistoryModel.apiStatus != "PENDING_CERT") {
            if (data1.resubmitModel.enrollModel.applicationHistoryModel.enrollJSON != null) {
              this.isEnrolledError = true;
              if (this.isEnrolledError == true) {
                this.enrolledErrorMessage = JSON.parse(data1.resubmitModel.enrollModel.applicationHistoryModel.enrollJSON);
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  transferDetails(personalDetailsForm, addressForm, dependentForm, qualifyForm, otherDetailForm) {
  
    this.apperrorMessage="";
    this.isEligiblityError = false;
    this.isEnrolledError = false;
    this.isSubmittedPersonalDetails = true;
    this.isSubmittedAddress = true;
    this.isSubmittedDependant = true;
    this.isSubmittedOtherDetails = true;
    if (this.personalDetailsForm.invalid) {
      return;
    }
    this.isSubmittedPersonalDetails = true;
    if (this.addressForm.invalid) {
      return;
    }
    if (this.isDependent) {
      if (this.dependentForm.invalid) {
        return;
      }
    }
    if (this.qualifyForm.invalid) {
      return;
    }
    if (this.confirmReasonForm.value['isEnrolledInNLAD'] == 'Transfer') {
      if (this.otherDetailForm.invalid) {
        return;
      }
    }
    if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
      this.toastr.error("Only alphbet allow in mail address city.", "Error");
      return;
    }
    if(this.isFPHAChecked && this.publicHousingCodeValue==''){
      this.toastr.error("Please select federal public housing program options.", "Error");
      return;
    }
  
    // if (otherDetailForm.controls.applicationNumber.value == '' || otherDetailForm.controls.applicationNumber.value == null) {
    //   this.toastr.error("Please enter application number.", "Error");
    //   return;
    // }
    // else {
    //   if (otherDetailForm.controls.applicationNumber.value.length < 11) {
    //     this.toastr.error("Invalid length of application number.", "Error");
    //     return;
    //   }
    // }
    this.cApp.subscriberPersonalDetails = personalDetailsForm.value;
    this.cApp.subscriberAddress = addressForm.value;
    this.cApp.subscriberDependent = dependentForm.value;
    this.cApp.subscriberQualify = qualifyForm.value;
    this.cApp.subscriberAddress.stateShortName = this.stateShortNameValue;
    this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;
    this.cApp.subscriberApplicationHistory.approvalDate = null;
    this.cApp.subscriberApplicationHistory.createdDate = null;
    this.cApp.subscriberApplicationHistory.modifiedDate = null;
    this.cApp.subscriberPersonalDetails.isTransferAppno=personalDetailsForm.value.chkapplicationid;
    
    if (this.cApp.subscriberAddress.addressType == 'Home') {
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameTempValue;
      if (this.cApp.subscriberAddress.stateIdTemp == null || this.cApp.subscriberAddress.stateIdTemp == 0) {
        return;
      }
      if (!alphabetOnly(addressForm.controls.cityTemp.value)) {
        this.toastr.error("Only alphbet allow in mail city.", "Error");
        return;
      }
    }
    else {
      if (!alphabetOnly(addressForm.controls.city.value)) {
        this.toastr.error("Only alphbet allow in city.", "Error");
        return;
      }
      this.cApp.subscriberAddress.cityTemp = this.cApp.subscriberAddress.city;
      this.cApp.subscriberAddress.stateIdTemp = this.cApp.subscriberAddress.stateId;
      this.cApp.subscriberAddress.stateShortNameTemp = this.stateShortNameValue;
      this.cApp.subscriberAddress.streetNumberTemp = this.cApp.subscriberAddress.streetNumber;
      this.cApp.subscriberAddress.zipcodeTemp = this.cApp.subscriberAddress.zipcode;
    }
    this.cApp.subscriberAddress.isTribalLands = this.isTribalLands;
    let dt = new Date(this.cApp.subscriberPersonalDetails.dateofBirth);
    this.cApp.subscriberPersonalDetails.dateofBirth = this.setDateFormat(dt, false);
    if (this.isDependent) {
      dt = new Date(this.cApp.subscriberDependent.dateofBirth);
      this.cApp.subscriberDependent.dateofBirth = this.setDateFormat(dt, false);
    }
    let enrollDate = otherDetailForm.value.enrollmentDate;
    let locationId = this.locationList.join(',');
    let zone = this.zoneList.join(',');
    // this.cApp.subscriberPersonalDetails.locationCode = otherDetailForm.value.accountNumber.toString().substring(0, 8);
    // this.cApp.subscriberPersonalDetails.customerId = otherDetailForm.value.accountNumber.toString().substring(8, 16);
    this.cApp.subscriberPersonalDetails.applicationNumber = otherDetailForm.value.applicationNumber.toString();
    this.cApp.subscriberPersonalDetails.enrollDate = enrollDate;
    this.cApp.subscriberPersonalDetails.locationId = locationId;
    this.cApp.subscriberPersonalDetails.zone = zone;
    this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = otherDetailForm.value.eligibilityExpirationDate;
    this.cApp.subscriberPersonalDetails.transactionEffectiveDate = otherDetailForm.value.transactionEffectiveDate;
    this.cApp.subscriberPersonalDetails.serviceInitializationDate = otherDetailForm.value.serviceInitializationDate;
    let dt1 = new Date(this.cApp.subscriberPersonalDetails.enrollDate);
    this.cApp.subscriberPersonalDetails.enrollDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.transactionEffectiveDate);
    this.cApp.subscriberPersonalDetails.transactionEffectiveDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.serviceInitializationDate);
    this.cApp.subscriberPersonalDetails.serviceInitializationDate = this.setDateFormat(dt1, true);
    dt1 = new Date(this.cApp.subscriberPersonalDetails.eligibilityExpirationDate);
    this.cApp.subscriberPersonalDetails.eligibilityExpirationDate = this.setDateFormat(dt1, true);

    if(this.onlineRegistrationId>0 && this.onlineRegistrationId!=null){
      this.cApp.subscriberPersonalDetails.onlineRegistrationID = this.onlineRegistrationId.toString();
    }
    // Application Stage
    this.cApp.subscriberApplicarionStage.isPersonalDetailsCompleted = true;
    this.cApp.subscriberApplicarionStage.isAddressCompleted = true;
    this.cApp.subscriberApplicarionStage.isQualifiedCompleted = true;
    this.cApp.subscriberApplicarionStage.isAgreementCompleted = true;
    this.cApp.subscriberApplicarionStage.isReviewCompleted = true;
    // Other Details
    this.cApp.subscriberOthers.deviceMake = '';
    this.cApp.subscriberOthers.deviceReimbursementDate = null;
    this.cApp.subscriberOthers.deviceType = '';
    this.cApp.subscriberOthers.expectedRate = '';
    this.cApp.subscriberOthers.expectedRateDevice = '';
    this.cApp.subscriberOthers.latitude = '';
    this.cApp.subscriberOthers.longitude = '';
    this.cApp.subscriberOthers.schoolName = '';
    this.cApp.subscriberPersonalDetails.isEnrolledInNLAD = 'Yes';
    this.cApp.subscriberApplicationHistory.createdById = this.userId;
    this.cApp.subscriberApplicationHistory.modifiedById = this.userId;
    this.cApp.subscriberPersonalDetails.isAlreadyEnrolledRadioChecked = this.isAlreadyEnrolledRadioChecked;
    this.cApp.subscriberPersonalDetails.isConsumerFee = otherDetailForm.value.isConsumerFee;
    this.cApp.subscriberQualify.publicHousingCode=this.publicHousingCodeValue;
    this.isloading = true;
    if (this.cApp.subscriberPersonalDetails.customerId == "" || this.cApp.subscriberPersonalDetails.customerId == null) {
      this.cApp.subscriberPersonalDetails.customerId = "000000";
    }
    if (this.cApp.subscriberPersonalDetails.locationCode == "" || this.cApp.subscriberPersonalDetails.locationCode == null) {
      this.cApp.subscriberPersonalDetails.locationCode = "000001";
    }

    if (this.enableConsentDateTime) {
      let formattedDate =''
      if(otherDetailForm.value.consentDateTime!=null && otherDetailForm.value.consentDateTime!=''){
        this.cApp.subscriberPersonalDetails.ConsentDateTime = otherDetailForm.value.consentDateTime.toString();
        let Consentdt = new Date(this.cApp.subscriberPersonalDetails.ConsentDateTime);
        formattedDate = this.setDateFormat(Consentdt, true);
      }
      let formattedTime =''
      if(otherDetailForm.value.consentTime!='' && otherDetailForm.value.consentTime!=null){
        formattedTime = this.getTimeFormat(otherDetailForm.value.consentTime);
      }
      if(formattedDate!='' && formattedTime!=''){
        this.cApp.subscriberPersonalDetails.ConsentDateTime = formattedDate + ' ' + formattedTime;
      }
      this.cApp.subscriberPersonalDetails.consentZoneId = otherDetailForm.value.consentZoneId.toString();
    }

    this.subscriberService.TransferInApplication(this.cApp).subscribe(data => {
      this.isloading = false;
      if (data.status == "Success") {
        console.log(data);
        this.toastr.success(data.error_Description, 'Success');
        if (sessionStorage.getItem('applicationHistoryId') != null) {
          sessionStorage.removeItem('applicationHistoryId');
        }
        if (sessionStorage.getItem('isOnlineApplicationHistoryId') != null) {
          sessionStorage.removeItem('isOnlineApplicationHistoryId');
        }
        if (this.onlineRegistrationId > 0 && this.resubmitApplicationHistoryId == 0)
          this.redirectToOnlineRegistrationList();
        else
          this.redirectToList();
      }
      else {
        this.isEnrolledError = true;
        if(data['applicationHistory']!=null && data['applicationHistory']!=''){
          this.enrolledErrorMessage=  JSON.parse(data['applicationHistory'].enrollJSON);
        }
        // this.enrolledErrorMessage.header.failureType = data.error_Type;
        // this.enrolledErrorMessage.body = data.error_Description;
        this.toastr.error(data.error_Description, 'Error');
        this.apperrorMessage = "";
        if (data.error_Description.split("\n").length > 1) {
          if (data.error_Description.split("\n")[2] == "APPLICATION_NOT_FOUND") {
            this.apperrorMessage = "Please submit the application using first radio button";
          }
          else if (data.error_Description.split("\n")[2] == "SUBSCRIBER_NOT_FOUND_ERROR") {
            this.apperrorMessage = "Please submit the application using first radio button";
          }
        }
      }
    },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }
  selectFPHAprogram(publicHousingCodeValue){
    this.publicHousingCodeValue=publicHousingCodeValue;
  }
  OpenModel() {
    this.openConfirmModal.nativeElement.click();
  }
  OpenProcessModal() {
    if (this.confirmReasonForm.value['isEnrolledInNLAD'] == 'Yes') {
      this.openProceedModal.nativeElement.click();
    }
  }

  getConsentZone() {
    this.stateService.GetConsentZoneList().subscribe(data => {
      if (data) {
        this.consentZonelist = data;
        if(this.consentZonelist.length>1){
          this.odf.consentZoneId.setValidators([Validators.required]);
        }
        else{
          this.odf.consentZoneId.clearValidators();
        }
        this.odf.consentZoneId.updateValueAndValidity();
      }
    }, error => {
    });
  }
  
  getTimeFormat(date: Date): string {
    if (date != null) {
      let hours = date.getHours().toString();
      if (parseInt(hours) < 10)
        hours = '0' + hours;
  
      let minutes = date.getMinutes().toString();
      if (parseInt(minutes) < 10)
        minutes = '0' + minutes;
  
      let seconds = date.getSeconds().toString();
      if (parseInt(seconds) < 10)
        seconds = '0' + seconds;
  
      return hours + ':' + minutes + ':' + seconds;
    } else {
      return null;
    }
  }

  onTimeInput(event: any) {
    const input = event.target.value;
    // Use a regular expression to allow only numeric characters
    const numericInput = input.replace(/[^0-9]/g, '');
    // Update the input field with the validated value
    if (numericInput !== input) {
      event.target.value = numericInput;
    }
  }

  isApplicationIdcheck($event) {
    this.isApplicationidValue = $event.currentTarget.checked;
    if(this.isApplicationidValue){
      this.odf.applicationNumber.setValidators([Validators.required]);      
    }
    else{
      this.odf.applicationNumber.clearValidators();      
    }
    this.odf.applicationNumber.updateValueAndValidity();
  }
}